const Mission = require("../Mission")
const MissionType = require("../enums/Mission")

class Pictionary extends Mission {
  constructor(props) {
    super(props)
    this.time = 100
    this.points = 100
    this.behavior = MissionType.DrawingPictionary

    this.setDefaultModes()
  }
}

module.exports = Pictionary
