







































import Vue from "vue"

import { ValidationObserver } from "vee-validate"
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation.vue"

import PictionaryMission from "@shared/missions/Pictionary"
import AddMissionMixin from "@/components/ActivityMenuPanels/AddMissionMixin"
export default Vue.extend({
  name: "AddPictionary",
  components: { ValidationObserver, WeveTextFieldWithValidation },
  mixins: [AddMissionMixin],
  data() {
    return {
      question: "",
      answer: ""
    }
  },
  methods: {
    async addPictionary() {
      const mission = new PictionaryMission({
        title: "Pictionary ",
        name: this.question,
        answer: this.answer,
        stayOnCurrentMission: true
      })
      await this.$_AddMissionMixin_add(mission)
    }
  }
})
