const Mission = require("../Mission")
const MissionType = require("../enums/Mission")
const PlayType = require("../enums/PlayType")

class Poll extends Mission {
  constructor(props) {
    super(props)
    this.time = 60
    this.points = 5
    this.behavior = MissionType.Poll
    this.playType = PlayType.INDIVIDUAL_SPEED_DOES_NOT_MATTER
    this.answers = props.answers || []
    this.answers.forEach((answer, idx) => {
      this[`answer${idx + 1}`] = answer.value
    })

    this.setDefaultModes()
  }
}

module.exports = Poll
