var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-observer", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var localInvalid = ref.invalid
          return [
            _c(
              "weve-text-field-with-validation",
              _vm._b(
                {
                  attrs: { help: "", disabled: _vm.disabled, rules: _vm.rules },
                  on: {
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.$emit("add", _vm.innerValue)
                    },
                  },
                  model: {
                    value: _vm.innerValue,
                    callback: function ($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue",
                  },
                },
                "weve-text-field-with-validation",
                _vm.$attrs,
                false
              )
            ),
            _c(
              "div",
              { staticClass: "absolute right-0 bottom-0" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "black--text font-weight-bold",
                    attrs: {
                      round: "",
                      disabled:
                        localInvalid ||
                        _vm.disabled ||
                        _vm.innerValue === _vm.value,
                      color: "#ffd44c",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("add", _vm.innerValue)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.loaded ? "ADDED" : "ADD") + " ")]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }