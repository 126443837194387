const Mission = require("../Mission")
const MissionType = require("../enums/Mission")
const PlayType = require("../enums/PlayType")

class MultipleChoice extends Mission {
  constructor(props) {
    super(props)
    this.behavior = MissionType.MultipleChoice
    this.time = 30
    this.multiCorrect = props.multiCorrect || null
    this.numOfTries = 1
    this.answers.forEach((answer, idx) => {
      this[`answer${idx + 1}`] = answer.value
    })

    this.setDefaultModes()
  }
}

module.exports = MultipleChoice
