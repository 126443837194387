<template>
  <div class="add-new-multiple-choice-panel px-3 mt-2">
    <ValidationObserver v-slot="{ invalid }">
      <WeveTextAreaWithValidation
        label="Question"
        v-model="question"
        :hint="`${countCharacters(question, 100)} Characters Left`"
        maxlength="100"
        required
        message="Required"
        rules="required|max:100"
      />
      <hr class="divider my-2" />
      <v-radio-group style="display: inherit" v-model="correctAnswer">
        <div :key="idx" v-for="(answer, idx) in answers" class="mb-2">
          <div style="position: relative">
            <WeveTextFieldWithValidation
              :label="`Answer ${idx + 1}`"
              :placeholder="answer.placeholder"
              :hint="`${countCharacters(answer.value)} Characters Left`"
              v-model="answer.value"
              maxlength="36"
              rules="required|max:36"
            />
            <v-radio
              class="absolute right-0 mb-0"
              label="Correct Answer"
              style="bottom: -5px"
              :value="idx + 1"
            />
            <div
              v-if="idx > 1"
              class="absolute right-0"
              style="width: 20px; cursor: pointer; top: -5px"
              @click="removeAnswer(idx)"
            >
              <v-icon small>close</v-icon>
            </div>
          </div>
        </div>
      </v-radio-group>
      <div class="mb-3" style="display: flex">
        <WeveButton
          class="ml-auto"
          size="sm"
          variant="secondary"
          @click="addAnswer"
        >
          <template #prepend>
            <v-icon className="mr-1"> add_circle</v-icon>
          </template>
          ADD ANSWER
        </WeveButton>
      </div>

      <WeveButton
        class="mb-3"
        size="sm"
        variant="primary"
        block
        :disabled="invalid"
        @click="addNewMultipleChoiceMission"
      >
        ADD MULTIPLE CHOICE QUESTION
      </WeveButton>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate"
import { WeveButton } from "@weve/ui"
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation"
import WeveTextAreaWithValidation from "@/components/WeveTextAreaWithValidation"

import { countCharacters } from "@/utils/characters"

import MultipleChoiceMission from "@shared/missions/MultipleChoice"
import AddMissionMixin from "@/components/ActivityMenuPanels/AddMissionMixin"

export default {
  name: "AddMultipleChoice",
  components: {
    WeveTextFieldWithValidation,
    WeveTextAreaWithValidation,
    WeveButton,
    ValidationObserver
  },
  mixins: [AddMissionMixin],
  data() {
    return {
      answers: [
        {
          placeholder: "e.g. Mercury",
          value: ""
        },
        {
          placeholder: "e.g. Venus",
          value: ""
        }
      ],
      question: "",
      correctAnswer: 1
    }
  },
  methods: {
    countCharacters,
    addAnswer() {
      this.answers.push({
        placeholder: "",
        value: ""
      })
    },
    removeAnswer(idx) {
      this.answers.splice(idx, 1)
    },
    async addNewMultipleChoiceMission() {
      const mission = new MultipleChoiceMission({
        title: "Trivia",
        name: this.question,
        multiCorrect: this.correctAnswer,
        instructions: this.question,
        answers: this.answers
      })
      await this.$_AddMissionMixin_add(mission)
    }
  }
}
</script>
<style lang="scss">
.add-new-multiple-choice-panel {
  .v-input--selection-controls {
    .v-label {
      font-size: 14px;
    }
  }
}
</style>
