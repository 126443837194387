var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-categories-question-panel px-3 mt-2" },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var invalid = ref.invalid
              return [
                _c("WeveTextAreaWithValidation", {
                  attrs: {
                    label: "Description",
                    maxlength: "100",
                    rules: "required|max:100",
                    required: "",
                    message: "Required",
                  },
                  model: {
                    value: _vm.description,
                    callback: function ($$v) {
                      _vm.description = $$v
                    },
                    expression: "description",
                  },
                }),
                _c("hr", { staticClass: "divider my-3" }),
                _vm._l(_vm.answers, function (answer, idx) {
                  return _c(
                    "div",
                    { key: idx, staticClass: "relative mb-3" },
                    [
                      _c("WeveTextFieldWithValidation", {
                        attrs: {
                          label: "Answer " + (idx + 1),
                          placeholder: answer.placeholder,
                          maxlength: "20",
                          required: "",
                          message: "Required",
                          rules: "required|max:20",
                          "characters-counter": "",
                        },
                        model: {
                          value: answer.value,
                          callback: function ($$v) {
                            _vm.$set(answer, "value", $$v)
                          },
                          expression: "answer.value",
                        },
                      }),
                      idx > 5
                        ? _c(
                            "div",
                            {
                              staticClass: "absolute right-0",
                              staticStyle: {
                                width: "20px",
                                cursor: "pointer",
                                top: "-5px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeAnswer(idx)
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("close"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticClass: "mb-3", staticStyle: { display: "flex" } },
                  [
                    _c(
                      "WeveButton",
                      {
                        staticClass: "ml-auto",
                        attrs: { size: "sm", variant: "secondary" },
                        on: { click: _vm.addAnswer },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend",
                              fn: function () {
                                return [_c("v-icon", [_vm._v(" add_circle ")])]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_vm._v(" ADD ANSWER ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "WeveButton",
                  {
                    staticClass: "mb-3",
                    attrs: {
                      size: "sm",
                      variant: "primary",
                      block: "",
                      disabled: invalid,
                    },
                    on: { click: _vm.addCategories },
                  },
                  [_vm._v(" ADD CATEGORIES QUESTION ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }