const OFFSET = 0.01

import NavigationService from "@/services/navigation.service"
import MissionCollection from "@shared/MissionCollection"
import { findLast } from "lodash"

export default {
  methods: {
    $_AddMissionMixin_setLoadingIndicator(val) {
      this.$emit("loading", val)
    },
    async $_AddMissionMixin_add(...array) {
      this.$_AddMissionMixin_setLoadingIndicator(true)
      let missions = MissionCollection.normalize(this.$store.getters.missions)
      const currentMission = this.$store.getters.getCurrentMission
      let missionKey = currentMission.id
      if (currentMission.activityId) {
        missionKey = findLast(
          missions,
          mission => mission.activityId === currentMission.activityId
        )?.id
      }
      await this.$store.dispatch("addToMissions", {
        array,
        missionKey
      })

      missions = MissionCollection.normalize(this.$store.getters.missions)
      const missionIndex = missions.findIndex(item => item.id === missionKey)

      const missionToGo = missions[missionIndex + 1]
      if (!array[0].stayOnCurrentMission) {
        await NavigationService.navigateTo(
          this.$store.getters.orgID,
          this.$store.getters.actualGameID,
          missionToGo?.id
        )
      }

      this.$_AddMissionMixin_setLoadingIndicator(false)
    }
  }
}
