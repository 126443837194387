<template>
  <div class="activity-pannel-locker" v-if="isCopyOfContentToolTemplate">
    <div class="activity-pannel-locker_cnt">
      <p>
        If you want to modify this game, you have to lock the content. This
        means the game won't receive any updates from its template.
      </p>
      <WeveButton
        class="my-2"
        size="sm"
        variant="primary"
        block
        :loading="loading"
        @click="unlock"
      >
        LOCK IN
      </WeveButton>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "@vue/composition-api"
import useStore from "@/use/useStore"
import useInfo from "@/use/useInfo"
import { WeveButton } from "@weve/ui"
import { isNil } from "lodash"

export default defineComponent({
  name: "ActivityPanelLocker",
  components: { WeveButton },
  setup() {
    const { info } = useInfo()
    const { store } = useStore()
    const loading = ref()

    const game = computed(() => store.getters.game)
    const templateID = computed(() => game.value?.templateID)
    const isCopyOfContentToolTemplate = computed(() => !isNil(templateID.value))
    async function unlock() {
      loading.value = true
      try {
        const gameService = await store.$services.get("game")
        await gameService.unassignGameFromTemplate(game.value)
      } catch (error) {
        info(error.message)
      }
      loading.value = false
    }

    return {
      loading,
      unlock,
      isCopyOfContentToolTemplate
    }
  }
})
</script>

<style lang="scss">
.activity-pannel-locker {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;

  &__cnt {
    width: 100%;
    text-align: center;
  }
}
</style>
