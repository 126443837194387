<template>
  <validation-provider
    v-slot="{ errors, valid }"
    :name="$attrs.label"
    :rules="rules"
  >
    <WeveTextarea v-model="innerValue" v-bind="$attrs" v-on="$listeners" />
  </validation-provider>
</template>
<script>
import { ValidationProvider } from "vee-validate"

import { WeveTextarea } from "@weve/ui"

export default {
  name: "WeveTextFieldWithValidation",
  components: {
    ValidationProvider,
    WeveTextarea
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
