var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative", staticStyle: { height: "210px" } },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var invalid = ref.invalid
              return [
                _c("WeveTextFieldWithValidation", {
                  attrs: {
                    placeholder: "Pictionary prompt",
                    label: "Pictionary",
                    maxlength: "50",
                    help: "",
                    "characters-counter": "",
                    rules: "required|max:50",
                  },
                  model: {
                    value: _vm.question,
                    callback: function ($$v) {
                      _vm.question = $$v
                    },
                    expression: "question",
                  },
                }),
                _c("WeveTextFieldWithValidation", {
                  attrs: {
                    placeholder: "Answer",
                    "hide-label": "",
                    label: "Pictionary Answer",
                    maxlength: "50",
                    help: "",
                    "characters-counter": "",
                    rules: "required|max:50",
                  },
                  model: {
                    value: _vm.answer,
                    callback: function ($$v) {
                      _vm.answer = $$v
                    },
                    expression: "answer",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "absolute right-0 bottom-0" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "black--text font-weight-bold",
                        attrs: {
                          disabled: invalid,
                          round: "",
                          color: "#ffd44c",
                        },
                        on: { click: _vm.addPictionary },
                      },
                      [_vm._v(" ADD ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }