const Mission = require("../Mission")
const MissionType = require("../enums/Mission")
const PlayType = require("../enums/PlayType")

class Categories extends Mission {
  constructor(props) {
    super(props)

    this.points = 5
    this.playType = PlayType.INDIVIDUAL_SPEED_DOES_NOT_MATTER
    this.behavior = MissionType.Categories
    this.time = 60
    this.answers = props.answers || []
    this.answer = this.answers.map(answer => answer.value).join(",")

    this.setDefaultModes()
  }
}

module.exports = Categories
