const MissionType = require("../enums/Mission")
const Mission = require("../Mission")

class Question extends Mission {
  constructor(props) {
    super(props)
    this.time = 30
    this.points = 30
    this.behavior = MissionType.Text
    this.numOfTries = 1

    this.setDefaultModes()
  }
}

module.exports = Question
