

























import Vue from "vue"
import { ValidationObserver } from "vee-validate"
import { WeveButton } from "@weve/ui"
import WeveTextFieldButtonGroup from "@/components/WeveTextFieldButtonGroup.vue"
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation.vue"

import AddMissionMixin from "@/components/ActivityMenuPanels/AddMissionMixin"

import AddPictionary from "./Fields/AddPictionary.vue"
import AddYouTubeMission from "./Fields/AddYouTubeMission.vue"
import AddQuestion from "./Fields/AddQuestion.vue"

import { PannelNames } from "./ActivityMenuPanels.consts"

export default Vue.extend({
  name: "AddQuestions",
  components: {
    AddPictionary,
    AddYouTubeMission,
    AddQuestion,
    ValidationObserver,
    WeveTextFieldWithValidation,
    WeveTextFieldButtonGroup,
    WeveButton
  },
  mixins: [AddMissionMixin],
  computed: {
    items() {
      return [
        {
          title: "Multiple Choice",
          value: PannelNames.MultipleChoice
        },
        {
          title: PannelNames.Categories,
          value: PannelNames.Categories
        },
        {
          title: PannelNames.Poll,
          value: PannelNames.Poll
        }
      ]
    }
  }
})
