var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCopyOfContentToolTemplate
    ? _c("div", { staticClass: "activity-pannel-locker" }, [
        _c(
          "div",
          { staticClass: "activity-pannel-locker_cnt" },
          [
            _c("p", [
              _vm._v(
                " If you want to modify this game, you have to lock the content. This means the game won't receive any updates from its template. "
              ),
            ]),
            _c(
              "WeveButton",
              {
                staticClass: "my-2",
                attrs: {
                  size: "sm",
                  variant: "primary",
                  block: "",
                  loading: _vm.loading,
                },
                on: { click: _vm.unlock },
              },
              [_vm._v(" LOCK IN ")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }