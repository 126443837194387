<template>
  <div class="add-new-poll-panel px-3 mt-2">
    <ValidationObserver v-slot="{ invalid }">
      <WeveTextAreaWithValidation
        label="Description"
        placeholder="e.g. Name the 8 planets of our solar system"
        v-model="description"
        rules="required|max:100"
        required
        message="Required"
        :hint="`${countCharacters(description, 100)} Characters Left`"
      />

      <hr class="divider my-2" />

      <div class="relative mb-3" :key="idx" v-for="(answer, idx) in answers">
        <WeveTextFieldWithValidation
          :placeholder="answer.placeholder"
          :label="`Answer ${idx + 1}`"
          :rules="`${idx < 2 ? 'required|' : ''}max:20`"
          v-model="answer.value"
          maxlength="20"
          :required="idx < 2"
          message="Required"
          characters-counter
        />

        <div
          v-if="idx > 1"
          class="absolute right-0"
          style="width: 20px; cursor: pointer; top: -5px"
          @click="removeAnswer(idx)"
        >
          <v-icon small>close</v-icon>
        </div>
      </div>

      <div class="mb-3" style="display: flex">
        <WeveButton
          class="ml-auto"
          size="sm"
          variant="secondary"
          @click="addAnswers"
        >
          <template #prepend>
            <v-icon className="mr-1"> add_circle</v-icon>
          </template>
          ADD ANSWER
        </WeveButton>
      </div>

      <WeveButton
        class="mb-3"
        size="sm"
        variant="primary"
        block
        :disabled="invalid"
        @click="addPollMission"
      >
        ADD POLL
      </WeveButton>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate"

import { countCharacters } from "@/utils/characters"

import PollMission from "@shared/missions/Poll"
import { WeveButton } from "@weve/ui"
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation"
import WeveTextAreaWithValidation from "@/components/WeveTextAreaWithValidation"
import AddMissionMixin from "@/components/ActivityMenuPanels/AddMissionMixin"

import { answerTrmplates, answerTrmplate } from "./ActivityMenuPanels.consts"

const getDefaultState = () => {
  return {
    answers: answerTrmplates().slice(0, 3),
    description: ""
  }
}
export default {
  name: "AddPoll",
  components: {
    ValidationObserver,
    WeveTextFieldWithValidation,
    WeveButton,
    WeveTextAreaWithValidation
  },
  mixins: [AddMissionMixin],
  data() {
    return {
      ...getDefaultState()
    }
  },
  methods: {
    countCharacters,
    addAnswers() {
      this.answers.push(answerTrmplate())
    },
    removeAnswer(idx) {
      this.answers.splice(idx, 1)
    },
    async addPollMission() {
      const mission = new PollMission({
        title: "Poll",
        name: "Added Poll " + this.description,
        instructions: this.description,
        answers: this.answers
      })
      await this.$_AddMissionMixin_add(mission)
      Object.assign(this.$data, getDefaultState())
    }
  }
}
</script>
