var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-new-multiple-choice-panel px-3 mt-2" },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var invalid = ref.invalid
              return [
                _c("WeveTextAreaWithValidation", {
                  attrs: {
                    label: "Question",
                    hint:
                      _vm.countCharacters(_vm.question, 100) +
                      " Characters Left",
                    maxlength: "100",
                    required: "",
                    message: "Required",
                    rules: "required|max:100",
                  },
                  model: {
                    value: _vm.question,
                    callback: function ($$v) {
                      _vm.question = $$v
                    },
                    expression: "question",
                  },
                }),
                _c("hr", { staticClass: "divider my-2" }),
                _c(
                  "v-radio-group",
                  {
                    staticStyle: { display: "inherit" },
                    model: {
                      value: _vm.correctAnswer,
                      callback: function ($$v) {
                        _vm.correctAnswer = $$v
                      },
                      expression: "correctAnswer",
                    },
                  },
                  _vm._l(_vm.answers, function (answer, idx) {
                    return _c("div", { key: idx, staticClass: "mb-2" }, [
                      _c(
                        "div",
                        { staticStyle: { position: "relative" } },
                        [
                          _c("WeveTextFieldWithValidation", {
                            attrs: {
                              label: "Answer " + (idx + 1),
                              placeholder: answer.placeholder,
                              hint:
                                _vm.countCharacters(answer.value) +
                                " Characters Left",
                              maxlength: "36",
                              rules: "required|max:36",
                            },
                            model: {
                              value: answer.value,
                              callback: function ($$v) {
                                _vm.$set(answer, "value", $$v)
                              },
                              expression: "answer.value",
                            },
                          }),
                          _c("v-radio", {
                            staticClass: "absolute right-0 mb-0",
                            staticStyle: { bottom: "-5px" },
                            attrs: { label: "Correct Answer", value: idx + 1 },
                          }),
                          idx > 1
                            ? _c(
                                "div",
                                {
                                  staticClass: "absolute right-0",
                                  staticStyle: {
                                    width: "20px",
                                    cursor: "pointer",
                                    top: "-5px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeAnswer(idx)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("close"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "mb-3", staticStyle: { display: "flex" } },
                  [
                    _c(
                      "WeveButton",
                      {
                        staticClass: "ml-auto",
                        attrs: { size: "sm", variant: "secondary" },
                        on: { click: _vm.addAnswer },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend",
                              fn: function () {
                                return [
                                  _c(
                                    "v-icon",
                                    { attrs: { className: "mr-1" } },
                                    [_vm._v(" add_circle")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_vm._v(" ADD ANSWER ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "WeveButton",
                  {
                    staticClass: "mb-3",
                    attrs: {
                      size: "sm",
                      variant: "primary",
                      block: "",
                      disabled: invalid,
                    },
                    on: { click: _vm.addNewMultipleChoiceMission },
                  },
                  [_vm._v(" ADD MULTIPLE CHOICE QUESTION ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }