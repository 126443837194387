const Mission = require("../Mission")
const MissionType = require("../enums/Mission")

class Youtube extends Mission {
  constructor(props) {
    super(props)
    this.behavior = MissionType.YouTube
    this.time = 60
    this.points = 0
    this.youtube = Youtube.parseId(props.youtube)

    this.setDefaultModes()
  }
  static parseId(url = "") {
    const id = url.split("v=")[1]
    const index = id.indexOf("&")
    return index !== -1 ? id.substring(0, index) : id
  }
}

module.exports = Youtube
