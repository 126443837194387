







































import Vue from "vue"
import AddMissionMixin from "@/components/ActivityMenuPanels/AddMissionMixin"

import { ValidationObserver } from "vee-validate"
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation.vue"

import QuestionMission from "@shared/missions/Question"

export default Vue.extend({
  name: "AddQuestion",
  components: { ValidationObserver, WeveTextFieldWithValidation },
  mixins: [AddMissionMixin],
  data() {
    return {
      question: "",
      answer: ""
    }
  },
  methods: {
    async addQuestion() {
      const mission = new QuestionMission({
        title: "Trivia",
        name: this.question,
        answer: this.answer,
        instructions: this.question
      })
      await this.$_AddMissionMixin_add(mission)
    }
  }
})
