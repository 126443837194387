<template>
  <validation-observer v-slot="{ invalid: localInvalid }">
    <weve-text-field-with-validation
      v-model="innerValue"
      v-bind="$attrs"
      help=""
      :disabled="disabled"
      :rules="rules"
      @keypress.enter="$emit('add', innerValue)"
    />
    <div class="absolute right-0 bottom-0">
      <v-btn
        @click="$emit('add', innerValue)"
        class="black--text font-weight-bold"
        round
        :disabled="localInvalid || disabled || innerValue === value"
        color="#ffd44c"
      >
        {{ loaded ? "ADDED" : "ADD" }}
      </v-btn>
    </div>
  </validation-observer>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"

import { countCharacters } from "@/utils/characters"

import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation"

export default {
  name: "WeveTextFieldWithValidationAddBtn",
  components: {
    ValidationProvider,
    WeveTextFieldWithValidation,
    ValidationObserver
  },
  props: {
    rules: {
      type: [Object, String],
      default: "required|max:50"
    },
    value: {
      type: undefined
    },
    loading: {
      type: Boolean
    },
    loaded: {
      type: Boolean
    }
  },
  data() {
    return {
      innerValue: ""
    }
  },
  computed: {
    disabled() {
      return this.loaded || this.loading
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    }
  },
  methods: {
    countCharacters
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
