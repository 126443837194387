<template>
  <div class="add-categories-question-panel px-3 mt-2">
    <ValidationObserver v-slot="{ invalid }">
      <WeveTextAreaWithValidation
        label="Description"
        v-model="description"
        maxlength="100"
        rules="required|max:100"
        required
        message="Required"
      />
      <hr class="divider my-3" />
      <div class="relative mb-3" :key="idx" v-for="(answer, idx) in answers">
        <WeveTextFieldWithValidation
          :label="`Answer ${idx + 1}`"
          :placeholder="answer.placeholder"
          v-model="answer.value"
          maxlength="20"
          required
          message="Required"
          rules="required|max:20"
          characters-counter
        />
        <div
          v-if="idx > 5"
          class="absolute right-0"
          style="width: 20px; cursor: pointer; top: -5px"
          @click="removeAnswer(idx)"
        >
          <v-icon small>close</v-icon>
        </div>
      </div>
      <div class="mb-3" style="display: flex">
        <WeveButton
          class="ml-auto"
          size="sm"
          variant="secondary"
          @click="addAnswer"
        >
          <template #prepend>
            <v-icon> add_circle </v-icon>
          </template>
          ADD ANSWER
        </WeveButton>
      </div>

      <WeveButton
        class="mb-3"
        size="sm"
        variant="primary"
        block
        @click="addCategories"
        :disabled="invalid"
      >
        ADD CATEGORIES QUESTION
      </WeveButton>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate"
import { mapGetters } from "vuex"

import { WeveButton } from "@weve/ui"
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation"
import WeveTextAreaWithValidation from "@/components/WeveTextAreaWithValidation"

import CategoriesMission from "@shared/missions/Categories"
import AddMissionMixin from "@/components/ActivityMenuPanels/AddMissionMixin"

import { answerTrmplates, answerTrmplate } from "./ActivityMenuPanels.consts"

export default {
  name: "AddCategories",
  mixins: [AddMissionMixin],
  components: {
    WeveTextFieldWithValidation,
    WeveTextAreaWithValidation,
    WeveButton,
    ValidationObserver
  },
  data() {
    return {
      description: "",
      answers: [...answerTrmplates(), answerTrmplate(), answerTrmplate()]
    }
  },
  computed: {
    ...mapGetters({
      mission: "getCurrentMission"
    })
  },
  methods: {
    addAnswer() {
      this.answers.push(answerTrmplate())
    },
    removeAnswer(idx) {
      this.answers.splice(idx, 1)
    },
    async addCategories() {
      const mission = new CategoriesMission({
        title: "Categories",
        name: "Added Category " + this.description,
        instructions: this.description,
        answers: this.answers
      })
      await this.$_AddMissionMixin_add(mission)
    }
  }
}
</script>
