



























import Vue from "vue"
import { ValidationObserver } from "vee-validate"
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation.vue"

import YoutubeMission from "@shared/missions/Youtube"
import AddMissionMixin from "@/components/ActivityMenuPanels/AddMissionMixin"
export default Vue.extend({
  name: "AddYouTubeMission",
  components: { ValidationObserver, WeveTextFieldWithValidation },
  mixins: [AddMissionMixin],
  data() {
    return {
      youtubeURL: ""
    }
  },
  methods: {
    async addYoutubeURL() {
      const mission = new YoutubeMission({
        name: "Youtube Link Added",
        title: "Your Youtube Link",
        youtube: this.youtubeURL
      })
      await this.$_AddMissionMixin_add(mission)
    }
  }
})
