var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { name: _vm.$attrs.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          var valid = ref.valid
          return [
            _c(
              "WeveTextarea",
              _vm._g(
                _vm._b(
                  {
                    model: {
                      value: _vm.innerValue,
                      callback: function ($$v) {
                        _vm.innerValue = $$v
                      },
                      expression: "innerValue",
                    },
                  },
                  "WeveTextarea",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }