var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-3 mt-3" },
    [
      _c(
        "v-layout",
        { staticClass: "my-2", attrs: { flex: "", column: "" } },
        _vm._l(_vm.items, function (item) {
          return _c(
            "WeveButton",
            {
              key: item.title,
              staticClass: "mb-2",
              attrs: { size: "sm", variant: "secondary", block: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("showSubMenu", item.value)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.title) + " ")]
          )
        }),
        1
      ),
      _c("hr", { staticClass: "divider my-3" }),
      _c("AddPictionary", {
        staticClass: "mb-2",
        on: {
          loading: function ($event) {
            return _vm.$emit("loading", $event)
          },
        },
      }),
      _c("AddQuestion", {
        staticClass: "mb-2",
        on: {
          loading: function ($event) {
            return _vm.$emit("loading", $event)
          },
        },
      }),
      _c("AddYouTubeMission", {
        staticClass: "mb-2",
        on: {
          loading: function ($event) {
            return _vm.$emit("loading", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }